import { Box, Container, Stack, CircularProgress, Alert, AlertTitle } from "@mui/material";
import axios from "axios";
import React from "react";
import ProductPricingTable from "./ProductPricingTable";
import LoadingButton from '@mui/lab/LoadingButton';
import { getAuth } from "firebase/auth";
import { useParams, useSearchParams } from "react-router-dom"
import StoreSelector from "./StoreSelector";
import { SERVICE_URL } from "./App";
import { getProducts } from "./managers/ProductManager";
import TaskList from "./InksoftSyncoreImport/TaskList";

function PricingPage(props) {
    let { db } = props;
    let { domainId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    let [products, setProducts] = React.useState([]);
    let [originalProducts, setOriginalProducts] = React.useState([]);
    let [submitting, setSubmitting] = React.useState(false);
    let [enableSizeGroups] = React.useState(false);
    let [error, setError] = React.useState(null);
    let [productError, setProductError] = React.useState(null);

    const getData = React.useCallback(async () => {
        try {           
            setProductError(null); 
            let products = await getProducts(domainId, searchParams.get("inksoftStoreURL"), true, false, true, true);
            setProducts(products);
            setOriginalProducts(JSON.parse(JSON.stringify(products)));
        } catch (error) {
            setProductError(error.toString());
        }
    }, [domainId, searchParams]);
    
    React.useEffect(() => {
        if (searchParams.get("inksoftStoreURL") !== null)
            getData();
    }, [searchParams, getData]);


    const handleStoreChange = (storeUrl) => {
        let newParams = new URLSearchParams(
            searchParams.toString()
        );
        newParams.set("inksoftStoreURL", storeUrl);
        setSearchParams(newParams);
        setProducts([]);
        // this.setState({inksoftUrl: event.target.value, products: []}, () => this.getData());
    }

    const submit = async () => {
        setSubmitting(true);
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        let submitProducts = [];
        let skuSet = new Set();
        for (let pIdx = 0; pIdx < products.length; pIdx++) {
            let product = products[pIdx];
            let originalProduct = originalProducts[pIdx];
            for (let cIdx = 0; cIdx < product.styles.length; cIdx++) {
                let color = product.styles[cIdx];
                let originalColor = originalProduct.styles[cIdx];
                for (let sIdx = 0; sIdx < color.sizes.length; sIdx++) {
                    let size = color.sizes[sIdx];
                    let originalSize = originalColor.sizes[sIdx]
                    if (size.supplierCost !== originalSize.supplierCost || size.price !== originalSize.price) {
                        if (!skuSet.has(product["sku"])) {
                            submitProducts.push(product);
                            skuSet.add(product["sku"]);
                        }
                    }
                }
            }
        }
        try {
            if (submitProducts.length > 0) {
                await axios.post(
                    `${SERVICE_URL}/inksoft/setProducts`,
                    {
                        products: submitProducts
                    },
                    {
                        params: {
                            "domainId": domainId,
                            "updateInventory": "false",
                            "updatePricing": "true",
                        },
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }
                );
            }   
            setSubmitting(false);
            setProducts([]);
            setError(null);
            await getData();
        } catch (e) {
            console.log(e.response.data.message);
            let error = e.response.data.message ?? "Fatal error";
            setSubmitting(false);
            setError(error);
        }
    }

    return (
        <Container>
            <Stack
                mt={1}
                direction='column'
                alignItems='center'
            >
                <TaskList db={db} types={["updateInksoftProductFull"]} />
                <Alert style={{marginTop: "8px"}} severity="warning">
                    <AlertTitle>Warning</AlertTitle>
                    Once changes are saved, an asynchronous task is created for each product updated. You can view the progress above. Changes will not be reflected until these tasks are completed.
                </Alert>
                <StoreSelector value={searchParams.get("inksoftStoreURL") ?? ""} handleStoreChange={handleStoreChange}/>
                {products.length === 0 && searchParams.get("inksoftStoreURL") !== null && productError === null &&
                    <Box sx={{marginLeft: "auto", marginRight: "auto", width: 100, height: "40vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <CircularProgress />
                    </Box>
                }
                {
                    productError !== null &&
                    <Alert sx={{margin: "16px"}} severity="error">{productError}</Alert>
                }
                {products.map((p, idx) =>
                    <ProductPricingTable key={p.id.toString() + enableSizeGroups.toString()} enableSizeGroups={enableSizeGroups} index={idx} product={p} originalProduct={originalProducts[idx]} onValueChanged={() => {}}></ProductPricingTable>
                )}
            </Stack>
            {error !== null &&
                <Alert sx={{margin: "16px"}} severity="error">{error}</Alert>
            }
            {
                products.length !== 0 &&
                <Box padding={2} paddingBottom={4} display="flex" justifyContent="center">
                    <LoadingButton loading={submitting} variant="contained" onClick={submit}>Update Products</LoadingButton>
                </Box>
            }
        </Container>
    );
}

export default PricingPage