import { useParams } from "react-router-dom";
import * as PayoutManager from "../managers/PayoutManager";
import React from "react";
import { Box, Button, Card, CardContent, Container, DialogActions, IconButton, Paper, Stack, TableContainer, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Search } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import PayoutCard from "./PayoutCard";
import dayjs from "dayjs";

const getStartDate = (payout) => {
    let startDateSet = new Set(payout.details.map(d => d.startDate));
    if (startDateSet.size === 1) {
        return startDateSet.values().next().value;
    } else {
        return "Multiple";
    }
}

const getEndDate = (payout) => {
    let endDateSet = new Set(payout.details.map(d => d.endDate));
    if (endDateSet.size === 1) {
        return endDateSet.values().next().value;
    } else {
        return "Multiple";
    }
}

const RecipientPage = () => {
    const { domainId, recipientId } = useParams();

    const [recipient, setRecipient] = React.useState(null);
    const [payouts, setPayouts] = React.useState(null);
    const [viewPayout, setViewPayout] = React.useState(null);

    React.useEffect(() => {
        PayoutManager.getRecipient(domainId, recipientId, setRecipient);
        PayoutManager.getRecipientPayouts(domainId, recipientId, setPayouts);
    }, [domainId, recipientId]);

    return (
        <Container sx={{mt: 2, mb: 2}}>
            {
                recipient &&
                <Stack gap={2}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Details</Typography>
                            <Typography variant="body1">Recipient Name: {recipient.recipientName}</Typography>
                            <Typography variant="body1">Account: {recipient.accountRef.name}</Typography>
                            <Typography variant="body1">Contact Name: {recipient.contactRef.name}</Typography>
                            <Typography variant="body1">Contact Email: {recipient.contactRef.email}</Typography>
                        </CardContent>
                    </Card>
                    <TableContainer component={Paper}>
                        <Typography variant="h5" textAlign={"center"} m={2}>Payouts</Typography>
                        <Box>
                            <DataGrid
                                autoHeight
                                loading={payouts === null}
                                rows={payouts ?? []}
                                sx={{ border: 0 }}
                                pageSizeOptions={[10]}
                                localeText={{ noRowsLabel: "No payouts" }}
                                columns={[
                                    { field: 'dateCreated', headerName: 'Date Created', width: 200, valueFormatter: (params) => params.value?.toDate()?.toLocaleDateString() ?? "N/A"},
                                    { field: 'startDate', headerName: 'Start Date', width: 150, valueGetter: (params) => getStartDate(params.row), valueFormatter: (params) => dayjs(params.value)?.toDate()?.toLocaleDateString() ?? "N/A"},
                                    { field: 'endDate', headerName: 'End Date', width: 150, valueGetter: (params) => getEndDate(params.row), valueFormatter: (params) => dayjs(params.value)?.toDate()?.toLocaleDateString() ?? "N/A" },
                                    { field: 'total', headerName: 'Total', width: 150, valueGetter: (params) => params.row.totals.total, valueFormatter: (params) => `$${params.value.toFixed(2)}` },
                                    {
                                        field: 'actions', headerName: 'Actions', width: 100, renderCell: (params) => (
                                            <Stack
                                                direction={"row"}
                                            >
                                                <IconButton aria-label="expand row" size="small" onClick={() => {
                                                    setViewPayout(params.row);
                                                }}>
                                                    <Search />
                                                </IconButton>
                                            </Stack>
                                        )
                                    }
                                ]}
                            />
                        </Box>
                    </TableContainer>
                </Stack>
            }
            {
                viewPayout &&
                <Dialog open={Boolean(viewPayout)} onClose={() => setViewPayout(null)} maxWidth={"xl"}>
                    <DialogTitle>Payout Details</DialogTitle>
                    <DialogContent>
                        <PayoutCard payout={viewPayout} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setViewPayout(null)}>Close</Button>
                    </DialogActions>
                </Dialog>
            }
        </Container>
    );
}

export default RecipientPage;