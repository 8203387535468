import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Stack, Typography, CircularProgress, Box, Autocomplete } from '@mui/material';
import AccountSelector from '../Components/AccountSelector';
import ContactSelector from '../Components/ContactSelector';
import * as AdvanceManager from '../managers/AdvanceManager';
import * as PayoutManager from '../managers/PayoutManager';
import ProductSelector from '../Components/ProductSelector';
import InksoftStoreSelector from '../Components/InksoftStoreSelector';

const EditRecipientDialog = ({ domainId, open, onClose, recipient }) => {
    const [accountRef, setAccountRef] = useState(recipient?.accountRef ?? null);
    const [contactRef, setContactRef] = useState(recipient?.contactRef ?? null);
    const [contact, setContact] = useState(null);
    const [recipientName, setRecipientName] = useState(recipient?.recipientName ?? "");
    const [paymentType, setPaymentType] = useState("Check");
    const [inksoftStoreRef, setInksoftStoreRef] = useState(null);
    const [notes, setNotes] = useState(recipient?.notes ?? "");

    const [productMapping, setProductMapping] = useState(recipient?.productMapping ?? []);

    React.useEffect(() => {
        if (contactRef) {
            AdvanceManager.getContact(domainId, contactRef.id).then(setContact);
        } else {
            setContact(null);
        }
    }, [domainId, contactRef]);

    React.useEffect(() => {
        setAccountRef(recipient?.accountRef ?? null);
        setContactRef(recipient?.contactRef ?? null);
        setProductMapping(recipient?.productMapping ?? []);
        setRecipientName(recipient?.recipientName ?? "");
        setNotes(recipient?.notes ?? "");
    }, [recipient]);

    const handleSave = () => {
        let updatedRecipient = {
            recipientName,
            paymentType,
            accountRef,
            contactRef,
            notes,
        };
        if (inksoftStoreRef !== null) {
            updatedRecipient["inksoftStoreRef"] = inksoftStoreRef;
        }
        if (recipient) {
            PayoutManager.updateRecipient(domainId, recipient.id, updatedRecipient);
        } else {
            PayoutManager.createRecipient(domainId, updatedRecipient);
        }
        onClose();
    };

    const handleAccountUpdate = (account) => {
        setAccountRef(account);
        setContactRef(null);
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"xs"}>
            <DialogTitle>{recipient ? 'Edit Recipient' : 'Create Recipient'}</DialogTitle>
            <DialogContent>
                <Stack gap={2} sx={{mt: 1}}>
                    <TextField 
                        value={recipientName}
                        label="Recipient Name"
                        onChange={(e) => setRecipientName(e.target.value)}
                    />
                    <TextField 
                        value={notes}
                        label="Notes"
                        onChange={(e) => setNotes(e.target.value)}
                    />
                    <AccountSelector domainId={domainId} label={"Advance Account"} selectedAccountRef={accountRef} onUpdate={handleAccountUpdate} />
                    <ContactSelector domainId={domainId} label={"Advance Contact"} accountId={accountRef?.id} selectedContactRef={contactRef} onUpdate={setContactRef} />
                    <Autocomplete
                        value={paymentType}
                        options={["Check", "Inksoft Gift Card", "Manual"]}
                        onChange={(_, value) => setPaymentType(value)}
                        renderInput={(params) => <TextField {...params} label="Payment Type" />}
                    />
                    {
                        paymentType === "Inksoft Gift Card" &&
                        <InksoftStoreSelector domainId={domainId} label={"Inksoft Store"} selectedStoreRef={inksoftStoreRef} onUpdate={setInksoftStoreRef} />
                    }
                    {
                        contactRef &&
                        <Typography variant="subtitle1">Advance Contact Billing Address</Typography>
                    }
                    {
                        contactRef && !contact &&
                        <Box sx={{width: "100%", justifyContent: "center", display: "flex"}}>
                            <CircularProgress />
                        </Box>
                    }
                    {
                        contact &&
                        <>
                            <TextField
                                label="Email"
                                value={contact.email}
                                disabled
                            />
                            <TextField
                                label="Address 1"
                                value={contact.billing.address1}
                                error={!!!contact.billing.address1}
                                disabled
                            />
                            <TextField
                                label="Address 2"
                                value={contact.billing.address2}
                                disabled
                            />
                            <Stack direction={"row"} gap={2}>    
                                <TextField
                                    label="City"
                                    value={contact.billing.city}
                                    error={!!!contact.billing.city}
                                    disabled
                                />
                                <TextField
                                    label="State"
                                    value={contact.billing.state}
                                    error={!!!contact.billing.state}
                                    disabled
                                />
                            </Stack>
                            <Stack direction={"row"} gap={2}>
                                <TextField
                                    label="Postal Code"
                                    value={contact.billing.postalCode}
                                    error={!!!contact.billing.postalCode}
                                    disabled
                                />
                                <TextField
                                    label="Country"
                                    value={contact.billing.country}
                                    error={!!!contact.billing.country}
                                    disabled
                                />
                            </Stack>
                        </>
                    }
                    {
                        productMapping.map((pm, index) => (
                            <Stack direction={"row"} gap={2} key={index}>
                                <ProductSelector 
                                    domainId={domainId} 
                                    label={"Product"} 
                                    value={pm.productId}
                                    onChange={(productId) => {
                                        const newProductMapping = [...productMapping];
                                        newProductMapping[index].productId = productId;
                                        setProductMapping(newProductMapping);
                                    }}
                                />
                                <TextField
                                    label="Product SKU"
                                    value={pm.productSku}
                                    onChange={(e) => {
                                        const newProductMapping = [...productMapping];
                                        newProductMapping[index].productSku = e.target.value;
                                        setProductMapping(newProductMapping);
                                    }}
                                />
                            </Stack>
                        ))
                    }
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>CANCEL</Button>
                <Button onClick={handleSave} variant="text" color="primary">
                    SAVE
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditRecipientDialog;