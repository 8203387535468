import { Box, Card, CardContent, InputLabel, MenuItem, Select, FormControl, Typography, CircularProgress, Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React from "react";
import { getAuth } from "firebase/auth";
import { useParams, useSearchParams } from "react-router-dom";
import { SERVICE_URL } from "./App";

function StoreSelector(props) {
    let [stores, setStores] = React.useState([]);
    let { domainId } = useParams();
    let [searchParams,] = useSearchParams();


    const onChange = (_, value) => {
        console.log(value.uri)
        props.handleStoreChange(value.uri);
    }

    
    React.useEffect(() => {
        const getStoreData = async () => {
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken();
            console.log(domainId);
            let response = await axios.get(
                `${SERVICE_URL}/inksoft/getStores`,
                {
                    params: {
                        "domainId": domainId
                    },
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            setStores(response.data.data.stores.sort((a, b) => a.name.localeCompare(b.name)));
        }
        if (stores.length === 0)
            getStoreData();
    }, [searchParams, stores.length, domainId]);

    console.log(props.value, 'val')

    return (
        <Card sx={{margin: "8px", width: "800px"}}>
            <CardContent>
                <Typography gutterBottom textAlign="center" variant="h5" component="div">
                    Store Selection
                </Typography>
                <Box display="flex" alignItems="center" flexDirection="column" padding={1}>
                    {stores.length === 0 && 
                        <Box sx={{width: 100, height: 100, display: "flex", justifyContent: "center"}}>
                            <CircularProgress />
                        </Box>
                    }
                    {stores.length !== 0 &&                        
                        <FormControl>
                            <Autocomplete
                                sx={{width: "600px"}}
                                id="store-url-select"
                                value={stores?.find(s => s.uri === props.value) || null}
                                label="Store"
                                onChange={onChange}
                                options={stores ?? []}
                                getOptionKey={(option) => option.uri}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label='Store' />}
                            />
                        </FormControl>
                    }
                    {/* {this.state.stores.length !== 0 &&
                        <Stack
                            direction='row'
                            justifyContent='center'
                            marginTop={2}
                        >
                            <Box>
                                <FormControlLabel control={<Switch onChange={(event) => this.setState({enableSizeGroups: event.target.checked})} />} label="Group Common Sizes" />
                            </Box>
                        </Stack>
                    } */}
                </Box>
            </CardContent>
        </Card>
    );
}

export default StoreSelector;