import { Alert, Box, Button, CircularProgress, Container, IconButton, Paper, Stack, TableContainer, Typography } from "@mui/material";
import * as PayoutManager from "../managers/PayoutManager";
import * as AdvanceManager from "../managers/AdvanceManager";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import EditRecipientDialog from "./EditReceipientDialog";
import { Edit, ListAlt } from "@mui/icons-material";
import ProductDialog from "./ProductDialog";
import CreatePayoutsDialog from "./CreatePayoutsDialog";

const PayoutManagementPage = () => {
    let { domainId } = useParams();
    const [products, setProducts] = React.useState(null);
    const [recipients, setRecipients] = React.useState(null);
    const [serviceData, setServiceData] = React.useState(null);
    const [productError, setProductError] = React.useState(null);
    const [recipientDialogOpen, setRecipientDialogOpen] = React.useState(false);
    const [productDialogOpen, setProductDialogOpen] = React.useState(false);
    const [editRecipient, setEditRecipient] = React.useState(null);
    const [editProductId, setEditProductId] = React.useState(null);
    const [selected, setSelected] = React.useState([]);
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        return PayoutManager.getServiceDocSnapshot(domainId, setServiceData);
    }, [domainId]);

    React.useEffect(() => {
        return PayoutManager.getRecipients(domainId, setRecipients);
    }, [domainId]);

    React.useEffect(() => {
        if (serviceData?.productTagId) {
            setProductError(null);
            AdvanceManager.getProductsByTag(domainId, [serviceData.productTagId]).then((products) => {
                setProducts(products);
            }).catch((e) => {
                console.error(e);
                setProductError(e.toString());
            });
        }
    }, [domainId, serviceData?.productTagId]);
    
    const handleCloseRecipientDialog = () => {
        setRecipientDialogOpen(false);
        setEditRecipient(null);
    }

    const handleCloseProductDialog = () => {
        setProductDialogOpen(false);
        setEditProductId(null);
    }

    if (serviceData === null) {
        return (
            <Box sx={{marginLeft: "auto", marginRight: "auto", width: 100, height: "40vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container sx={{ mt: 2, mb: 2 }}>
            {
                productError !== null &&
                <Alert severity="error">
                    {productError}
                </Alert>
            }
            <Stack gap={2}>
                <Typography variant="h4" textAlign={"center"} m={2}>Payout Management</Typography>
                <TableContainer component={Paper}>
                    <Typography variant="h5" textAlign={"center"} m={2}>Advance Products</Typography>
                    <Box>
                        <DataGrid
                            autoHeight
                            rowSelection={false}
                            density="compact"
                            rows={products ?? []}
                            sx={{ border: 0 }}
                            pageSizeOptions={[10]}
                            loading={products === null}
                            columns={[
                                { field: 'name', headerName: 'Name', width: 500 },
                                { field: 'itemNo', headerName: 'Item No.', width: 500 },
                                {
                                    field: 'actions', headerName: 'Actions', width: 150, renderCell: (params) => (
                                        <Stack
                                            direction={"row"}
                                        >
                                            <IconButton aria-label="expand row" size="small" onClick={() => {
                                                console.log(params.row.id);
                                                setEditProductId(params.row.id);
                                                setProductDialogOpen(true);
                                            }}>
                                                <Edit />
                                            </IconButton>
                                        </Stack>
                                    )
                                }
                            ]}
                        />
                    </Box>
                </TableContainer>
                <TableContainer component={Paper}>
                    <Typography variant="h5" textAlign={"center"} m={2}>Recipients</Typography>
                    <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        m={2}
                    >                        
                        <Button variant={"outlined"} onClick={() => setRecipientDialogOpen(true)} sx={{mr: 1, mb: 1}}>Create Recipient</Button>
                        {
                            selected.length > 0 &&
                            <Button variant={"outlined"} onClick={() => setCreateDialogOpen(true)} sx={{mb: 1}}>Create Payouts</Button>
                        }
                    </Stack>
                    <Box>
                        <DataGrid
                            autoHeight
                            density="compact"
                            disableRowSelectionOnClick
                            rows={recipients ?? []}
                            sx={{ border: 0 }}
                            checkboxSelection
                            onRowSelectionModelChange={(newSelection) => {
                                setSelected(newSelection);
                            }}
                            pageSizeOptions={[10]}
                            columns={[
                                // { field: 'recipientName', headerName: 'Recipient Name', width: 200},
                                { field: 'accountName', headerName: 'Account Name', width: 300, valueGetter: (params) => params.row.accountRef.name},
                                { field: 'contactName', headerName: 'Contact Name', width: 200, valueGetter: (params) => params.row.contactRef.name },
                                { field: 'paymentType', headerName: 'Payment Type', width: 125, valueFormatter: (params) => params.value},
                                { field: 'notes', headerName: 'Notes', width: 200, valueFormatter: (params) => params.value},
                                { field: 'lastPayoutDate', headerName: 'Last Payout Date', width: 150, valueFormatter: (params) => params.value?.toDate()?.toLocaleDateString() ?? "N/A"},
                                {
                                    field: 'actions', headerName: 'Actions', width: 100, renderCell: (params) => (
                                        <Stack
                                            direction={"row"}
                                        >
                                            <IconButton aria-label="expand row" size="small" onClick={() => {
                                                setEditRecipient(params.row);
                                                setRecipientDialogOpen(true);
                                            }}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton aria-label="expand row" size="small" onClick={() => {
                                                navigate(`recipients/${params.row.id}`)
                                            }}>
                                                <ListAlt />
                                            </IconButton>
                                        </Stack>
                                    )
                                }
                            ]}
                        />
                    </Box>
                </TableContainer>
            </Stack>
            <EditRecipientDialog domainId={domainId} open={recipientDialogOpen} recipient={editRecipient} onClose={handleCloseRecipientDialog} />
            {
                productDialogOpen &&
                <ProductDialog 
                    domainId={domainId}
                    serviceData={serviceData}
                    open={productDialogOpen}
                    productId={editProductId}
                    onClose={handleCloseProductDialog}
                    recipients={recipients}
                    mapping={serviceData.mapping?.[editProductId] ?? {}}
                />
            }
            {
                createDialogOpen &&
                <CreatePayoutsDialog
                    domainId={domainId}
                    recipientIds={selected}
                    open={createDialogOpen}
                    preview={true}
                    onClose={() => setCreateDialogOpen(false)}
                />
            }
        </Container>
    );
}

export default PayoutManagementPage;
