import React, { useState } from "react";
import * as PayoutManager from "../managers/PayoutManager";
import { Alert, Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PayoutCard from "./PayoutCard";
import { Cancel, Check, KeyboardArrowDown, KeyboardArrowUp, Warning } from "@mui/icons-material";
import PayoutResultComponent from "./PayoutResultComponent";

const CreatePayoutsDialog = ({ domainId, recipientIds, preview, open, onClose }) => {
    const [payoutPreviews, setPayoutPreviews] = useState(null);
    const [payoutResults, setPayoutResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [endDateInput, setEndDateInput] = useState(dayjs().subtract(1, 'day').format("YYYY-MM-DD"));
    const [error, setError] = useState(null);

    const handleGetPayoutPreviews = async (endDate) => {
        try {
            setError(null);
            setIsLoading(true);
            let payoutPreviews = await PayoutManager.getPayoutPreviews(domainId, recipientIds, endDate);
            setPayoutPreviews(payoutPreviews);
        } catch (error) {
            console.log(error);
            setError(error.response.data?.message?.toString() ?? "Unknown Error");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreatePayouts = async (endDate) => {
        try {
            setError(null);
            setIsLoading(true);
            let payouts = await PayoutManager.createPayouts(domainId, recipientIds, endDate);
            console.log(payouts);
            setPayoutResults(payouts);
        } catch (error) {
            console.log(error);
            setError(error.response.data?.message?.toString() ?? "Unknown Error");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitEndDate = () => {
        if (preview) {
            handleGetPayoutPreviews(endDateInput);
        } else {
            handleCreatePayouts(endDateInput);
        }
    }

    return (
        <Dialog
            title="Create Payouts"
            open={open}
            onClose={onClose}
            maxWidth="xl"
            fullWidth
        >
            <DialogTitle>Preview & Create Payouts</DialogTitle>
            <DialogContent>
                {
                    error &&
                    <Alert severity="error">{error}</Alert>
                }
                {
                    isLoading &&
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                }
                {
                    payoutResults &&
                    <>
                        {
                            payoutResults.map((payoutResult, idx) => (
                                <PayoutResultComponent key={idx} payoutResult={payoutResult} />
                            ))
                        }
                    </>
                }
                {
                    !error && !isLoading && !payoutResults && payoutPreviews &&
                    <Stack gap={2} alignItems={"center"}>
                        <TableContainer component={Paper} variant="outlined">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Recipient Name</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell>Contact</TableCell>
                                        <TableCell>Payment Type</TableCell>
                                        <TableCell>Invoiced</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {payoutPreviews?.map((payoutPreview, idx) => (
                                        <Row key={idx} payout={payoutPreview} last={idx === payoutPreviews.length - 1} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button variant="contained" onClick={() => handleCreatePayouts(endDateInput)}>Create Payouts</Button>
                    </Stack>
                }
                {
                    !error && !isLoading && !payoutPreviews && !payoutResults &&
                    <Stack gap={2} alignItems={"center"} mt={1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                onChange={(d) => setEndDateInput(d?.startOf('day')?.format("YYYY-MM-DD") ?? null)}
                                value={endDateInput != null ? dayjs(endDateInput, "YYYY-MM-DD") : null}
                                label="End Date"
                                disableHighlightToday
                                shouldDisableDate={(date) => date.isAfter(dayjs().startOf('day')) || date.isSame(dayjs().startOf('day'))}
                            />
                        </LocalizationProvider>
                        <Button
                            variant="contained"
                            onClick={handleSubmitEndDate}
                        >
                            Preview Payouts
                        </Button>
                        {isLoading && <CircularProgress />}
                    </Stack>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {
                    (error || payoutResults) &&
                    <Button onClick={onClose}>Done</Button>
                }
            </DialogActions>
        </Dialog>
    );

};

function Row({ payout, last }) {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {payout.recipient.recipientName}
                </TableCell>
                <TableCell>{payout.recipient.accountRef.name}</TableCell>
                <TableCell>{payout.recipient.contactRef.name}</TableCell>
                <TableCell>{payout.recipient.paymentType}</TableCell>
                <TableCell>
                    {payout.missingOrderNos.length === 0 ? 
                        <Check color={"success"} /> : 
                        <Tooltip title={payout.missingOrderNos.join(", ")}>
                            <Warning color={"error"} />
                        </Tooltip>
                    }
                </TableCell>
                <TableCell align="right">${payout.totals.total.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <PayoutCard payout={payout} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default CreatePayoutsDialog;