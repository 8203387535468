import { Alert, CircularProgress, Container, Stack } from "@mui/material";
import React from "react";
import * as ProductManager from "../managers/ProductManager";
import * as StoreManager from "../managers/StoreManager";
import { useParams, useSearchParams } from "react-router-dom";
import StoreSettingsCard from "./StoreSettingsCard";
import ProductCard from "./ProductCard";
import StoreSelector from "../StoreSelector";
import { useDispatch, useSelector } from "react-redux";
import { commissionSettingsLoaded, loadSettings } from "./inksoftCommissionSlice";

const DEFAULT_COMMISSION_SETTINGS = {
    "enabled": false,
    "mode": "PricePercentage",
    "percentage": 0,
    "amount": 0,
    "vipDiscountCommissionEnabled": false,
};

function InksoftCommissionPage({ product }) {
    let { domainId } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    let [products, setProducts] = React.useState(null);
    let [loadingError, setLoadingError] = React.useState(null);

    const settingsLoaded = useSelector(commissionSettingsLoaded);

    const dispatch = useDispatch();

    React.useEffect(() => {
        const getProducts = async () => {
            try {
                setProducts(null);
                const products = await ProductManager.getProducts(domainId, searchParams.get("inksoftStoreUrl"), false, false);
                setProducts(products);
            } catch (error) {
                setLoadingError(error.toString());
            }
        };
        if (searchParams.get("inksoftStoreUrl") !== null) {
            getProducts();
        }
    }, [searchParams]);

    React.useEffect(() => {
        const getCommissionSettings = async () => {
            dispatch(loadSettings(null));
            const commissionSettings = await StoreManager.getStoreCommissionSettings(domainId, searchParams.get("inksoftStoreUrl"));
            dispatch(loadSettings(commissionSettings ?? DEFAULT_COMMISSION_SETTINGS));
        };
        if (searchParams.get("inksoftStoreUrl") !== null) {
            getCommissionSettings();
        }
    }, [searchParams])

    const handleStoreChange = (storeUrl) => {
        let newParams = new URLSearchParams(
            searchParams.toString()
        );
        newParams.set("inksoftStoreUrl", storeUrl);
        setSearchParams(newParams);
    }

    return (
        <Container>
            <Stack
                mt={1}
                gap={1}
                direction='column'
                alignItems='center'
            >
                <StoreSelector value={searchParams.get("inksoftStoreUrl") ?? ""} handleStoreChange={handleStoreChange} />
                {
                    searchParams.get("inksoftStoreUrl") != null &&
                    <>
                        {
                            settingsLoaded &&
                            <StoreSettingsCard domainId={domainId} storeIdentifier={searchParams.get("inksoftStoreUrl")} />
                        }
                        {   searchParams.get("inksoftStoreUrl") !== null && (products === null || !settingsLoaded) &&
                            <CircularProgress sx={{mt: 2}} />
                        }
                        <Stack
                            gap={1}
                        >
                            { products !== null && settingsLoaded &&
                                products.map((p, idx) => 
                                    <ProductCard
                                        key={idx}
                                        product={p}
                                    />
                                )
                            }
                        </Stack>
                    </>
                }
                {
                    loadingError &&
                    <Alert severity="error">{loadingError}</Alert>
                }
                
            </Stack>
        </Container>
    );
}

export default InksoftCommissionPage;